import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const About = () => {
  const [inView, setInView] = React.useState(false)

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  const banner = data.allFile.edges.find(el => el.node.name === 'tsk-newsletter-landscape');
  const headshot = data.allFile.edges.find(el => el.node.name === 'headshot-v2');

  return (
    <InView as="section" onChange={setInView} delay="200">
      <div className="about__image-wrapper">
        <GatsbyImage
          image={getImage(banner.node)}
          alt="Glossify Education product page on ipad against neutral background"
          loading="lazy"
          className="about__image"
        />
      </div>
      <div className="grid container about__main">
        <div className="about__content">
          <h2 className="about__title h3">We're digital experts who have worked with some of the world's most exciting brands, from prestigious luxury fashion houses in London and New York to high-growth ecommerce start-ups.</h2>
          <div className="about__text">
            <p>Our expertise in design and cutting-edge web technologies, paired with our drive for quality and creating genuine value for our clients, allow us to build digital solutions that are both creative and fundamentally practical.</p>
            <p>Thanks to our own backgrounds in business, marketing and customer experience, our clients often look to us for advice across their business. We aim to fit seamlessly into projects, providing insights and solutions where we are needed.</p>
            <p>We’re committed to partnering with brands whose values we connect with and for whom we feel we can provide meaningful and measurable impact.</p>
          </div>
        </div>

        <figure className="about__call-out call-out">
          <div className="about__call-out-image-wrapper">
            <GatsbyImage
              image={getImage(headshot.node)}
              alt="Marianne Ferguson, Founder at Ultrafine"
              loading="lazy"
              className=""
            />
          </div>
          <figcaption className="about__image-caption container">
            <span className="about__image-caption-text">Marianne Ferguson, Founder at Ultrafine (<a href="https://www.linkedin.com/in/marianne-c-ferguson/" target="_blank">LinkedIn</a>)
            </span>
          </figcaption>
        </figure>
      </div>
    </InView>
  )
}

export default About