import * as React from 'react'
import { Link } from 'gatsby'
import ArticleCard from './article-card'
import { InView } from 'react-intersection-observer';

const RecentArticles = ({ articles, totalCount }) => {
  const [inView, setInView] = React.useState(false)

  console.log(totalCount)

  const hasMoreArticles = () => {
    return totalCount > 2;
  }

  return (
    <InView as="section" onChange={setInView} delay="200">
      <div className="container">
        <div className={`recent-articles grid animate${inView ? ' fade-in': ''}${hasMoreArticles() ? '': ' recent-articles--padding-bottom'}`}>
          <h2 className="recent-articles__title h4">Our insights</h2>
          <ul className="subgrid-list list-unstyled" role="list">
            {
              articles.map((node) => (
                <li className="subgrid-list__list-item" key={node.id}>
                  <ArticleCard article={node} />
                </li>
              ))
            }
          </ul>
          {hasMoreArticles() && <div className="recent-articles__actions">
            <hr className="divider" />
            <div className="recent-articles__button-wrapper">
              <Link to="/articles" className="button button--tertiary">
                View all insights
                <svg width="20" height="8.5" viewBox="0 0 20 8.26" aria-hidden="true" focusable="false" className="button__icon">
                  <path d="M15.11,7.47l3.32-3.34L15.11.79,15.89,0,20,4.13,15.89,8.26Z" />
                  <path d="M19.31,4.69H0V3.57H19.31Z" />
                </svg>
              </Link>
            </div>
            <hr className="divider" />
          </div>}
        </div>
      </div>
    </InView>
  )
}

export default RecentArticles