import * as React from 'react'
import { InView } from 'react-intersection-observer';

const Banner = () => {
  const [inView, setInView] = React.useState(false)

  return (
    <InView as="section" onChange={setInView} initialInView="true" delay="200">
      <div className="container">
        <div className={`banner grid animate${inView ? ' fade-in': ''}`}>
          <h2 className="fade-in banner__title h1">We help ambitious, design-led brands unlock their full potential online.</h2>
        </div>
      </div>
    </InView>
  )
}

export default Banner