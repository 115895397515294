import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/banner'
import BannerWork from '../components/banner-work'
import About from '../components/about'
import Testimonials from '../components/testimonials'
import ListServices from '../components/list-services'
import RecentArticles from '../components/recent-articles'
import PageTransition from 'gatsby-plugin-page-transitions';
import Press from '../components/press'

const IndexPage = ({ data, location }) => {
  const articles = data.Articles.nodes;
  const work = data.Work.nodes;

  return (
    <Layout pageTitle="Home Page" location={location} pageClass={`template-index${work.length > 0 ? ' template-index--flush': ''}`}>
      <PageTransition>
        <div>
          {work.length === 0 && <Banner />}
          {work.length > 0 && <BannerWork work={work} />}
          <ListServices />
          <About />     
          <Testimonials />
          <Press />
          {articles.length > 0 && <RecentArticles articles={articles} totalCount={data.Articles.totalCount} />}
        </div>
      </PageTransition>
    </Layout>
  )
}

export const articlesQuery = graphql`
  query {
    Articles: allMdx(
        limit: 2,
        filter: {fields: {collection: {eq: "articles"}}}
        sort: {fields: frontmatter___date, order: DESC}
      ) {
      nodes {
        ...MarkdownDataPreviewArticles
      }
      totalCount
    }

    Work: allMdx(
        limit: 8,
        filter: {fields: {collection: {eq: "work"}}}
        sort: {fields: frontmatter___date, order: DESC}
      ) {
      nodes {
        ...MarkdownDataPreviewWork
      }
    }
  }
`

export default IndexPage
