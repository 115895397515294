import * as React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'gatsby'
import { InView } from 'react-intersection-observer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BannerWork = ({ work }) => {
  const [inView, setInView] = React.useState(false)
  const slideshow = React.useRef(null)

  const formatNumber = React.useCallback((i) => {
    let num = i.toString();
    return num.padStart(2, 0);
  }, []);

  return (
    <InView as="section" onChange={setInView} delay="200" className="banner-work">
      <div className="banner-work__text grid container">
        <div className="banner-work__text-inner">
          <h1><span className="highlighter">Design, development and strategy for high-growth brands.</span></h1>
        </div>
      </div>
      <div className={`banner-work__images animate${inView ? ' fade-in' : ''}`}>
        <div className="banner-work__slideshow">
          <Splide
            ref={slideshow}
            options={{
              arrows: false,
              type: "loop",
              rewind: "true",
              width: '100%',
              pagination: false,
              perPage: 1,
              padding: { left: '50vw' },
              autoplay: true,
              interval: 4000,
              updateOnMove: true,
              breakpoints: {
                1024: {
                  padding: { left: '0' },
                }
              }
            }}
          >
            {
              work.map((node, i) => (
                <SplideSlide key={node.id} >
                  <div className="banner-work__slide-inner">
                    <Link to={`/work/${node.slug}`} className="increase-target">
                      <span className='sr-only'>{node.frontmatter.title}</span>
                    </Link>
                    <div className="banner-work__slide-content">
                      <div className="banner-work__image-wrapper">
                        <GatsbyImage
                          image={getImage(node.frontmatter.hero_image)}
                          alt={node.frontmatter.hero_image_alt}
                          height="560"
                          width="560"
                          className="banner-work__image"
                        />
                      </div>
                      <div className="banner-work__slide-text">
                        <div className="banner-work__slide-title-wrapper">
                          <h3 className="banner-work__slide-title h6">{`${formatNumber(i + 1)} ${node.frontmatter.title}`}</h3>
                          <h4 className="banner-work__slide-subtitle h6">{node.frontmatter.subtitle}</h4>
                        </div>
                        <svg focusable="false" aria-hidden="true" width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M24.1772 11.9549L29.4921 6.60857L24.1772 1.26221L25.4299 0.00205612L31.9976 6.60857L25.4299 13.2151L24.1772 11.9549Z" fill="white"></path><path fillRule="evenodd" clipRule="evenodd" d="M30.8926 7.49976L-0.000141144 7.49976V5.71764L30.8926 5.71764V7.49976Z" fill="white"></path></svg>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              ))
            }

          </Splide>
        </div>
      </div>
    </InView>
  )
}

export default BannerWork