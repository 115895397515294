import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ArticleCard = ({ article }) => {
  return (
    <article className="article-card" aria-labelledby={`article-${article.id}`}>
      <Link to={`/articles/${article.slug}`} className="increase-target">
        <span className='sr-only'>{article.frontmatter.title}</span>
      </Link>
      <div className="article-card__image-wrapper">
        <GatsbyImage
          image={getImage(article.frontmatter.hero_image)}
          alt={article.frontmatter.hero_image_alt}
          height="400"
          width="400"
          className="article-card__image"
        />
      </div>
      <div className="article-card__info increase-target-button">
        <header className="article-card__header">
          <h3 className="article-card__title h5" id={`article-${article.id}`}>{article.frontmatter.title}</h3>
        </header>
        <p className="article-card__excerpt">{article.excerpt}</p>
        <div className="article-card__footer">
          <span className="article-card__link link button button--tertiary" aria-label={`Read article: ${article.frontmatter.title}`}>
            Read article
            <svg width="20" height="8.5" viewBox="0 0 20 8.26" aria-hidden="true" focusable="false" className="button__icon">
              <path d="M15.11,7.47l3.32-3.34L15.11.79,15.89,0,20,4.13,15.89,8.26Z" />
              <path d="M19.31,4.69H0V3.57H19.31Z" />
            </svg>
          </span>
        </div>
      </div>
    </article>
  )
}

export default ArticleCard