import * as React from 'react'
import { InView } from 'react-intersection-observer';

const ListServices = () => {
  const [inView, setInView] = React.useState(false)

  return (
    <InView as="section" onChange={setInView} delay="200">
      <div className="container">      
        <section className={`list-services grid animate${inView ? ' fade-in': ''}`}>
          <h2 className="list-services__title h4">Our services</h2>
          <ol className="list-services__list list-unstyled" role="list">
            <li className="list-services__list-item">
              <span className="list-services__item-number">01</span>
              <h3 className="list-services__item-title">UX &amp; UI Design</h3>
              <p className="list-services__text">We immerse ourselves in your brand, pairing your data with our know-how to design elegant, intuitive user experiences that guide customers along their buying journey and drive conversions.</p>
            </li>
            <li className="list-services__list-item">
              <span className="list-services__item-number">02</span>
              <h3 className="list-services__item-title">Digital Product Development</h3>
              <p className="list-services__text">We develop high-performance ecommerce websites, web apps and native iOS apps. Our extensive knowledge of the Shopify platform allows us to set you up quickly with the tools you need to scale.</p>
            </li>
            <li className="list-services__list-item">
              <span className="list-services__item-number">03</span>
              <h3 className="list-services__item-title">Strategy &amp; Support</h3>
              <p className="list-services__text">Whether it’s supporting you with your marketing strategy, auditing your SEO or identifying CRO opportunities, we’re on hand to help and advise.</p>
            </li>
          </ol>
        </section>
      </div>
    </InView>
  )
}

export default ListServices